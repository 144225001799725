import React, { useState, useEffect } from "react"
import MyIncentivesReport from "./myincentivesreport"
import sanityClient from "@sanity/client"
import dayjs from "dayjs"
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { auth } from "../firebase/config"

const client = sanityClient({
  projectId: "3g2qblp5",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: false,
  token: process.env.REACT_APP_SANITY,
})

const MyIncentives = () => {
  const [incentive, setIncentive] = useState([])
  const [count, setCount] = useState(0)
  const [monthlyIncentive, setMonthlyIncentive] = useState(0)
  const [fine, setFine] = useState({
    fine: "",
    sales_target: "",
    remark: "",
    task_product: [],
  })
  const [value, setValue] = React.useState(
    dayjs(
      new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    )
  )

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount)
  }

  const calculatePercentage = (current, target) => {
    return Math.min(Math.round((current / target) * 100), 100)
  }

  useEffect(() => {
    console.log(value.toISOString().split("T")[0])
    let currentDate = new Date(value)
    const pastDate = new Date(value)
    pastDate.setDate(currentDate.getDate() - 60)

    console.log("past", pastDate.toISOString())
    const data = async () => {
      const query = `*[_type == "salesman" && email == "${auth.currentUser.email}"]`
      const fine_response = await client.fetch(query)
      console.log("fine", fine_response)
      if (fine_response.length !== 0) {
        setFine({
          fine: fine_response[0].fine,
          remark: fine_response[0].remark,
          sales_target: fine_response[0].sales_target,
          task_product: fine_response[0].task_product,
        })
      }

      // setIncentive(productlist_response)
    }
    data()
  }, [])

  useEffect(() => {
    console.log(value.toISOString().split("T")[0])
    let currentDate = new Date(value)
    const pastDate = new Date(value)
    pastDate.setDate(currentDate.getDate() - 60)

    console.log("past", pastDate.toISOString())
    const data = async () => {
      const query = `*[_type == "sales" && status != "rejected" && user == "${
        auth.currentUser.uid
      }" && _createdAt >= "${pastDate.toISOString()}"] | order(_createdAt)`
      const productlist_response = await client.fetch(query)
      setIncentive(productlist_response)
    }
    data()
  }, [count])

  useEffect(() => {
    console.log(value.toISOString().split("T")[0])
    let current_date = new Date()
    const current_month = current_date.getMonth() + 1 // Months are 0-based

    function toISOWithIST(date) {
      const offset = 5.5 * 60 * 60 * 1000 // IST is UTC+5:30 in milliseconds
      const istDate = new Date(date.getTime())
      return istDate.toISOString() //.replace("Z", "+05:30")
    }
    const data = async () => {
      // Get the first and last days of the current month
      const startOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        1
      )
      const endOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        1
      )
      console.log(toISOWithIST(startOfMonth), toISOWithIST(endOfMonth))
      const query = `*[_type == "sales" && user == "${
        auth.currentUser.uid
      }" && approved == true && status == "paid" && _createdAt >= "${toISOWithIST(
        startOfMonth
      )}" && _createdAt <= "${toISOWithIST(endOfMonth)}"] | order(_createdAt)`
      console.log(query)
      const monthlyincentive_response = await client.fetch(query)
      console.log(monthlyincentive_response)
      let monthly_incentive = 0
      let total_sales = 0
      monthlyincentive_response.map((item) => {
        monthly_incentive =
          monthly_incentive +
          parseFloat(item.quantity) * parseFloat(item.achieved_slab_amount)
        total_sales =
          total_sales + parseFloat(item.sale_amount) * parseFloat(item.quantity)
      })
      setMonthlyIncentive({
        monthly_incentive: monthly_incentive,
        total_sales: total_sales,
      })
    }

    data()
  }, [count])

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  // console.log(
  //   incentive,
  //   monthlyIncentive,
  //   incentive.filter(
  //     (item) =>
  //       item.model_no === "PANASONIC S AC- 1.0 TR  CS-CU AU12ZKY5F INV 5"
  //   ),
  //   fine.task_product[0]?.model
  // )

  let array = []

  incentive.map((item) => {
    let obj = {
      serial_no: item.serial_no,
      date: item._createdAt,
      bill_series: item.bill_series,
      bill_no: item.bill_no,
      name: item.sales_man,
      brand: item.brand,
      product: item.product,
      model: item.model_no,
      sale_amount: item.sale_amount,
      mop: item.item_mop,
      sp: item.item_sp,
      lp: item.item_lp,
      nlc: item.nlc,
      achieved_slab: item.achieved_slab,
      incentive: item.achieved_slab_amount,
      quantity: item.quantity,
      total_Incentive: item.achieved_slab_amount * item.quantity,
      margin: (item.sale_amount - item.nlc) * item.quantity,
      exchange: item.exchange_product,
      status: item.status,
      bill_signedby: item.bill_signedby,
      remarks: item.remarks,
    }
    array.push(obj)
  })

  const exportToCSV = (fileName) => {
    const ws = XLSX.utils.json_to_sheet(array)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div className="incentivecard">
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <Card
            sx={{
              maxWidth: 300,
              minWidth: 300,
              // margin: "20px auto",
              backgroundColor: "#f5f5f5",
              boxShadow: 3,
            }}
          >
            <CardContent>
              {fine.sales_target && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{ fontWeight: "bold" }}
                  >
                    Sales Target
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={calculatePercentage(
                          monthlyIncentive.total_sales,
                          fine.sales_target
                        )}
                        sx={{ height: 10, borderRadius: 5 }}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">
                        {`${calculatePercentage(
                          monthlyIncentive.total_sales,
                          fine.sales_target
                        )}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" align="left">
                    {`${formatCurrency(
                      monthlyIncentive.total_sales
                    )} / ${formatCurrency(fine.sales_target)}`}
                  </Typography>
                </Box>
              )}
              {fine.task_product && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{ fontWeight: "bold" }}
                  >
                    Task Product
                  </Typography>
                  <Typography variant="subtitle2" align="left">
                    {fine.task_product[0]?.model}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={calculatePercentage(
                          incentive
                            .filter(
                              (item) =>
                                item.model_no === fine.task_product[0]?.model &&
                                item.approved === true &&
                                item.status === "paid"
                            )
                            .reduce(
                              (sum, item) =>
                                sum + (parseFloat(item.quantity) || 0),
                              0
                            ),
                          fine.task_product[0]?.quantity
                        )}
                        sx={{ height: 10, borderRadius: 5 }}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">
                        {`${calculatePercentage(
                          incentive
                            .filter(
                              (item) =>
                                item.model_no === fine.task_product[0]?.model &&
                                item.approved === true &&
                                item.status === "paid"
                            )
                            .reduce(
                              (sum, item) =>
                                sum + (parseFloat(item.quantity) || 0),
                              0
                            ),
                          fine.task_product[0]?.quantity
                        )}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" align="left">
                    {`${incentive
                      .filter(
                        (item) =>
                          item.model_no === fine.task_product[0]?.model &&
                          item.approved === true &&
                          item.status === "paid"
                      )
                      .reduce(
                        (sum, item) => sum + (parseFloat(item.quantity) || 0),
                        0
                      )} / ${
                      (fine.task_product[0]?.quantity,
                      fine.task_product[0]?.quantity)
                    }`}
                  </Typography>
                </Box>
              )}

              <Typography
                variant="h6"
                component="div"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Monthly Incentive
              </Typography>
              <Typography variant="h4" color="primary">
                ₹{monthlyIncentive?.monthly_incentive?.toFixed(2)}
              </Typography>
              {fine.fine && (
                <>
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Fine
                  </Typography>
                  <Typography variant="h4" style={{ color: "red" }}>
                    ₹{fine.fine}
                  </Typography>
                  <Typography
                    variant="p"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    {fine.remark}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </div>
        {/* <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <Card
            sx={{
              maxWidth: 300,
              minWidth: 300,
              backgroundColor: "#f5f5f5",
              boxShadow: 3,
            }}
          >
            <CardContent>
              {fine.sales_target && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" align="left">
                    Sales Target
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={calculatePercentage(
                          monthlyIncentive.total_sales,
                          fine.sales_target
                        )}
                        sx={{ height: 10, borderRadius: 5 }}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">
                        {`${calculatePercentage(
                          monthlyIncentive.total_sales,
                          fine.sales_target
                        )}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" align="left">
                    {`${formatCurrency(
                      monthlyIncentive.total_sales
                    )} / ${formatCurrency(fine.sales_target)}`}
                  </Typography>
                </Box>
              )}
              <Typography variant="h6" component="div" gutterBottom>
                Monthly Incentive
              </Typography>
              <Typography variant="h4" color="primary">
                ₹{monthlyIncentive.monthly_incentive}
              </Typography>
              <Typography variant="h6" component="div" gutterBottom>
                Fine
              </Typography>
              <Typography variant="h4" color="secondary">
                ₹{fine.fine}
              </Typography>
              <Typography variant="p" color="secondary">
                {fine.remark}
              </Typography>
            </CardContent>
          </Card>
        </div> */}
      </div>

      <MyIncentivesReport
        productlist={incentive}
        setCount={setCount}
        count={count}
      />
    </div>
  )
}

export default MyIncentives
