import React, { useState, useEffect } from "react"
import {
  Card,
  CardContent,
  TextField,
  Button,
  Autocomplete,
  Snackbar,
  Typography,
  Grid,
  Box,
  Container,
} from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import sanityClient from "@sanity/client"
import { auth } from "../firebase/config"

const client = sanityClient({
  projectId: "3g2qblp5",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: false,
  token: process.env.REACT_APP_SANITY,
})

let bill_series = await client.fetch(`*[_type == "billseries"]`)
let admin = await client.fetch(`*[_type == "admin"]`)
console.log("admin", admin)
let bill_series_rev = bill_series.map((item) => item.name)

const query = `*[_type == "salesman"]`
let salesman = []
const salesman_response = await client.fetch(query)
salesman_response.map((item) => {
  salesman.push(item.name)
})

const achievedslab = ["MOP", "LP", "SP", "FIX", "ASP"]

let products = []
const products_response = await client.fetch(
  `*[_type == "productlist"]{product, brand}`
)
products_response.map((item) => {
  products.push(item.product)
})
products = [...new Set(products)]

const Form = () => {
  const [open, setOpen] = useState(false)
  const [brand, setBrand] = useState("")
  const [product, setProduct] = useState("")
  const [model, setModel] = useState("")
  const [models, setModels] = useState([])
  const [brands, setBrands] = useState([])
  const [mop, setMOP] = useState({
    mop: "",
    lp: "",
    sp: "",
    mop_incentive: "",
    lp_incentive: "",
    sp_incentive: "",
  })

  useEffect(() => {
    setMOP({ mop: "", lp: "", sp: "" })
    const data = async () => {
      const model_response = await client.fetch(
        `*[_type == "productlist" && name == '${model}']{mop,lp,sp, mop_incentive, lp_incentive, sp_incentive}`
      )

      if (model_response.length !== 0) {
        setMOP({
          mop: model_response[0].mop,
          lp: model_response[0].lp,
          sp: model_response[0].sp,
          mop_incentive: model_response[0].mop_incentive,
          lp_incentive: model_response[0].lp_incentive,
          sp_incentive: model_response[0].sp_incentive,
        })
      } else {
        setMOP({ mop: "", lp: "", sp: "" })
      }
    }
    data()
  }, [model])

  useEffect(() => {
    const data = async () => {
      let brands_rev = []
      const brand_response = await client.fetch(
        `*[_type == "productlist" && product == '${product}']{brand}`
      )
      brand_response.map((item) => {
        brands_rev.push(item.brand)
      })

      brands_rev = [...new Set(brands_rev)]
      setBrands(brands_rev)
    }
    data()
  }, [product])

  useEffect(() => {
    const data = async () => {
      let models_rev = []
      const model_response = await client.fetch(
        `*[_type == "productlist" && brand == '${brand}' && product == '${product}']`
      )

      model_response.map((item) => {
        models_rev.push(item.name)
      })
      setModels(models_rev)
    }
    data()
  }, [brand, product])

  const schema = Yup.object({
    bill_series: Yup.string().required("Please fill bill series"),
    bill_no: Yup.string().required("Please fill bill no"),
    // sales_man: Yup.string().required("Please fill your name"),
    product: Yup.string().required("Please fill the product you sold"),
    brand: Yup.string().required("Please fill the brand"),
    model_no: Yup.string().required("Please fill model no"),
    sale_amount: Yup.number().required("Please fill sale amount").min(1),
    exchange_product: Yup.string().required("Please fill exchange product"),
    bill_signedby: Yup.string().required("Please fill who signed the bill"),
    quantity: Yup.number().required("Please fill quantity").min(1).integer(),
  })

  const formik = useFormik({
    initialValues: {
      bill_series: "",
      bill_no: "",
      sales_man: auth.currentUser?.displayName,
      sales_man_email: auth.currentUser?.email,
      achieved_slab: "",
      achieved_slab_amount: "",
      product: "",
      brand: "",
      model_no: "",
      item_mop: "",
      item_sp: "",
      item_lp: "",
      sale_amount: "",
      exchange_product: "",
      bill_signedby: "",
      quantity: "",
      user: auth.currentUser?.uid,
      approved: false,
    },
    onSubmit: async (values, helpers) => {
      // try {
      console.log(values)
      let product = await client.fetch(
        `*[_type == "productlist" && name == '${values.model_no}']`
      )
      let length = await client.fetch(
        `*[_type == "sales"]| order(_createdAt desc)[0]`
      )
      console.log("length", length)
      // values.serial_no = String(parseInt(length.serial_no) + 1)

      if (length?.serial_no) {
        values.serial_no = String(parseInt(length.serial_no) + 1)
      } else {
        values.serial_no = "1"
      }
      console.log(product)
      if (product.length !== 0) {
        values.item_mop = product[0].mop
        values.item_sp = product[0].sp
        values.item_lp = product[0].lp
        if (product[0].nlc) {
          values.nlc = product[0].nlc
        }
        let formula = product[0].formula
        values.achieved_slab = "None"
        values.achieved_slab_amount = String(0)
        if (formula === "mobileshot") {
          if (parseFloat(values.sale_amount) - parseFloat(product[0].mop) > 0) {
            values.achieved_slab_amount =
              (parseFloat(values.sale_amount) - parseFloat(product[0].mop)) *
              0.1
          }
        }
        if (formula === "smallapp") {
          if (parseFloat(values.sale_amount) >= parseFloat(product[0].lp)) {
            values.achieved_slab = "LP"
            values.achieved_slab_amount = product[0].lp_incentive
          }
          if (parseFloat(values.sale_amount) >= parseFloat(product[0].sp)) {
            values.achieved_slab = "SP"
            values.achieved_slab_amount = product[0].sp_incentive
          }
          if (parseFloat(values.sale_amount) >= parseFloat(product[0].mop)) {
            values.achieved_slab = "MOP"
            values.achieved_slab_amount = product[0].mop_incentive
          }

          if (
            parseFloat(values.sale_amount) < parseFloat(product[0].lp) &&
            parseFloat(values.sale_amount) - parseFloat(product[0].nlc) * 1.1 >=
              0
          ) {
            let inc =
              (parseFloat(values.sale_amount) - parseFloat(values.nlc)) * 0.05
            values.achieved_slab_amount = String(inc)
          }
        }
        if (formula === "mop" || formula === "mop10") {
          if (parseFloat(values.sale_amount) >= parseFloat(product[0].lp)) {
            values.achieved_slab = "LP"
            values.achieved_slab_amount = product[0].lp_incentive
          }
          if (parseFloat(values.sale_amount) >= parseFloat(product[0].sp)) {
            values.achieved_slab = "SP"
            values.achieved_slab_amount = product[0].sp_incentive
          }
          if (parseFloat(values.sale_amount) >= parseFloat(product[0].mop)) {
            values.achieved_slab = "MOP"
            values.achieved_slab_amount = product[0].mop_incentive
            if (
              parseFloat(values.sale_amount) - parseFloat(product[0].mop) >=
                500 &&
              formula === "mop10"
            ) {
              let inc =
                parseFloat(product[0].mop_incentive) +
                (parseFloat(values.sale_amount) - parseFloat(product[0].mop)) *
                  0.1
              values.achieved_slab_amount = String(inc)
            }
          }
        }

        values.sale_amount = String(values.sale_amount)
        values.quantity = String(values.quantity)

        let mutation = {
          _id: formik.values.bill_no,
          _type: "sales",
          ...values,
          user: auth.currentUser.uid,
          approved: false,
          sales_man: auth.currentUser.displayName,
          sales_man_email: auth.currentUser.email,
        }

        let mutations = [
          {
            create: mutation,
          },
        ]

        fetch(
          "https://3g2qblp5.api.sanity.io/v2021-10-21/data/mutate/production",
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_SANITY}`,
            },
            body: JSON.stringify({ mutations }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            console.log(response)
            if (response.transactionId) {
              setOpen(true)
              formik.resetForm()
              setModel("")
            }
            if (
              response.error &&
              response.error.items[0].error.type ===
                "documentAlreadyExistsError"
            ) {
              alert(
                "This Bill No is already Submitted. Please try with another Bill No."
              )
            }
          })
          .catch((err) => console.log(err))
      }
    },
    validationSchema: schema,
  })

  return (
    <Container maxWidth="md" className="contactformcontainer">
      <Card
        className="contactform"
        elevation={4}
        style={{ marginBottom: "5%" }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          sx={{ fontFamily: "'Libre Baskerville', serif", my: 4 }}
        >
          MOP INCENTIVE FORM
        </Typography>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                options={bill_series_rev}
                value={formik.values.bill_series}
                onChange={(event, item) => {
                  formik.setFieldValue("bill_series", item)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bill Series"
                    error={
                      formik.touched.bill_series &&
                      Boolean(formik.errors.bill_series)
                    }
                    helperText={
                      formik.touched.bill_series && formik.errors.bill_series
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Bill No"
                name="bill_no"
                value={formik.values.bill_no}
                error={formik.touched.bill_no && Boolean(formik.errors.bill_no)}
                helperText={formik.touched.bill_no && formik.errors.bill_no}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                options={products}
                value={formik.values.product}
                onChange={(event, item) => {
                  setProduct(item)
                  formik.setFieldValue("product", item)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product"
                    error={
                      formik.touched.product && Boolean(formik.errors.product)
                    }
                    helperText={formik.touched.product && formik.errors.product}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                options={brands}
                value={formik.values.brand}
                onChange={(event, item) => {
                  setBrand(item)
                  formik.setFieldValue("brand", item)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Brand"
                    error={formik.touched.brand && Boolean(formik.errors.brand)}
                    helperText={formik.touched.brand && formik.errors.brand}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={models}
                value={formik.values.model_no}
                onChange={(event, item) => {
                  formik.setFieldValue("model_no", item)
                  setModel(item)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Model"
                    error={
                      formik.touched.model_no && Boolean(formik.errors.model_no)
                    }
                    helperText={
                      formik.touched.model_no && formik.errors.model_no
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            {model !== "" && (
              <Grid item xs={12}>
                <Box
                  sx={{ bgcolor: "background.paper", p: 2, borderRadius: 1 }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Model Details:
                  </Typography>
                  <Typography variant="body2">
                    <strong>MOP:</strong> {mop.mop}, <strong>SP:</strong>{" "}
                    {mop.sp}, <strong>LP:</strong> {mop.lp}
                  </Typography>
                  <Typography variant="body2">
                    <strong>MOP Inc:</strong> {mop.mop_incentive},{" "}
                    <strong>SP Inc:</strong> {mop.sp_incentive},{" "}
                    <strong>LP Inc:</strong> {mop.lp_incentive}
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                value={formik.values.quantity}
                onChange={(event, item) => {
                  formik.setFieldValue("quantity", item)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Qty"
                    name="quantity"
                    error={
                      formik.touched.quantity && Boolean(formik.errors.quantity)
                    }
                    helperText={
                      formik.touched.quantity && formik.errors.quantity
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Item Sale Amount"
                name="sale_amount"
                type="number"
                value={formik.values.sale_amount}
                onChange={formik.handleChange}
                error={
                  formik.touched.sale_amount &&
                  Boolean(formik.errors.sale_amount)
                }
                helperText={
                  formik.touched.sale_amount && formik.errors.sale_amount
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                options={["Yes", "No"]}
                value={formik.values.exchange_product}
                onChange={(event, item) => {
                  formik.setFieldValue("exchange_product", item)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Exchange Product"
                    name="exchange_product"
                    error={
                      formik.touched.exchange_product &&
                      Boolean(formik.errors.exchange_product)
                    }
                    helperText={
                      formik.touched.exchange_product &&
                      formik.errors.exchange_product
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                options={admin.map((adminItem) => ({
                  label: adminItem.name,
                  value: adminItem.email,
                }))}
                value={formik.values.bill_signedby}
                onChange={(event, item) => {
                  formik.setFieldValue("bill_signedby", item?.value || "")
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bill Signed By"
                    name="bill_signedby"
                    error={
                      formik.touched.bill_signedby &&
                      Boolean(formik.errors.bill_signedby)
                    }
                    helperText={
                      formik.touched.bill_signedby &&
                      formik.errors.bill_signedby
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
              sx={{
                width: "200px",
                fontFamily: "'Libre Baskerville', serif",
                fontSize: "18px",
                borderRadius: "20px",
                backgroundColor: "black",
              }}
            >
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={10000}
        onClose={() => setOpen(false)}
        message="Thanks for submitting the form"
        ContentProps={{
          sx: {
            background: "green",
          },
        }}
      />
    </Container>
  )
}

export default Form
