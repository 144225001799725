import * as React from "react"
import Box from "@mui/material/Box"
import { DataGrid } from "@mui/x-data-grid"
import sanityClient from "@sanity/client"
import { Button } from "@mui/material"

const client = sanityClient({
  projectId: "3g2qblp5",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: false,
  token: process.env.REACT_APP_SANITY,
})

export default function DataReport({ productlist, count, setCount }) {
  const rows = productlist
  const handlePayment = (row, status) => {
    console.log(row, status)
    client
      .patch(`${row._id}`)
      .set({ status: status })
      .commit()
      .then((response) => {
        setCount(count + 1)
      })
  }
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 210,
      renderCell: (params) => {
        return params.row.status === "paid" ? (
          <>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => handlePayment(params.row, "")}
            >
              Mark as UnPaid
            </Button>
          </>
        ) : (
          <div style={{ display: "flex", gap: "5%" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handlePayment(params.row, "paid")}
            >
              Mark as Paid
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => handlePayment(params.row, "rejected")}
            >
              Reject
            </Button>
          </div>
        )
      },
    },
    {
      field: "serial_no",
      headerName: "S.No",
      width: 100,
      valueGetter: (params) => parseInt(params.row.serial_no),
    },
    {
      field: `${"_createdAt"}`,
      headerName: "Date",
      width: 200,
      // editable: true,
      valueGetter: (params) =>
        `${new Date(params.row._createdAt).toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
        })}`,
    },
    { field: "bill_series", headerName: "Bill Series", width: 100 },

    {
      field: "bill_no",
      headerName: "Bill No",
      width: 120,
      // editable: true,
    },
    {
      field: "sales_man",
      headerName: "Sales Man",
      width: 120,
      // editable: true,
    },
    {
      field: "sales_man_email",
      headerName: "Email",
      width: 120,
      // editable: true,
    },
    {
      field: "brand",
      headerName: "Brand",

      width: 200,
      // editable: true,
    },
    {
      field: "product",
      headerName: "Product",

      width: 200,
      // editable: true,
    },
    {
      field: "model_no",
      headerName: "Model No",

      width: 400,
      // editable: true,
    },
    {
      field: "sale_amount",
      headerName: "Sale Amount",
      width: 100,
      // editable: true,
    },
    {
      field: "item_mop",
      headerName: "MOP",
      width: 150,
      // editable: true,
    },
    {
      field: "item_sp",
      headerName: "SP",
      width: 150,
      // editable: true,
    },
    {
      field: "item_lp",
      headerName: "LP",
      width: 150,
      // editable: true,
    },
    {
      field: "nlc",
      headerName: "NLC",
      width: 150,
      // editable: true,
    },
    {
      field: "achieved_slab",
      headerName: "Achieved Slab",
      width: 120,
      // editable: true,
    },

    {
      field: "achieved_slab_amount",
      headerName: "Incentive",
      width: 120,
      // editable: true,
    },
    {
      field: "quantity",
      headerName: "Qty",
      width: 120,
      // editable: true,
    },
    {
      field: `Total Inc`,
      headerName: "Total Inc",
      width: 120,
      valueGetter: (params) =>
        parseInt(params.row.achieved_slab_amount) *
        parseInt(params.row.quantity),
      // editable: true,
    },
    {
      field: `Margin`,
      headerName: "Margin",
      width: 120,
      valueGetter: (params) =>
        params.row.nlc
          ? (parseInt(params.row.sale_amount) - parseInt(params.row.nlc)) *
            parseInt(params.row.quantity)
          : "NA",
      // editable: true,
    },

    {
      field: "exchange_product",
      headerName: "Exchange",
      width: 100,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: true,
      //   valueGetter: (params) =>
      //     params.row.status === "" ? "Mark as Paid" : params.row.status,
      //   // editable: true,
    },
    {
      field: "bill_signedby",
      headerName: "Bill Signed By",
      width: 100,
      editable: false,
      //   valueGetter: (params) =>
      //     params.row.status === "" ? "Mark as Paid" : params.row.status,
      //   // editable: true,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 200,
      editable: true,
      //   valueGetter: (params) =>
      //     params.row.status === "" ? "Mark as Paid" : params.row.status,
      //   // editable: true,
    },
    {
      field: "approved",
      headerName: "Approved",
      width: 200,
      editable: true,
      //   valueGetter: (params) =>
      //     params.row.status === "" ? "Mark as Paid" : params.row.status,
      //   // editable: true,
    },
  ]

  const handleCellEdit = (row, event) => {
    console.log(row.row._id, row.row.status, "")
    if (row.row.status === "") {
      client
        .patch(`${row.row._id}`)
        .set({ status: "paid" })
        .commit()
        .then((response) => {
          setCount(count + 1)
        })
    } else {
      client
        .patch(`${row.row._id}`)
        .set({ status: "" })
        .commit()
        .then((response) => {
          setCount(count + 1)
        })
    }
  }

  const handleCellEditRev = (row, field, value) => {
    console.log(row, field, value)
    if (field === "status") {
      client
        .patch(`${row._id}`)
        .set({ status: value })
        .commit()
        .then((response) => {
          setCount(count + 1)
        })
    }
    if (field === "remarks") {
      client
        .patch(`${row._id}`)
        .set({ remarks: value })
        .commit()
        .then((response) => {
          setCount(count + 1)
        })
    }
  }

  return (
    <Box
      sx={{
        height: 700,
        width: "100%",
        "& .paid": {
          backgroundColor: "#DAF7A6",
        },
        "& .notpaid": {
          backgroundColor: "#fff44f",
        },
        // "& .MuiDataGrid-row:hover": {
        //   backgroundColor: "black",
        //   color: "white",
        // },
      }}
    >
      <DataGrid
        getRowId={(item) => item._id}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        getRowClassName={(params) => {
          return params.row.approved === true ? "paid" : "notpaid"
        }}
        // onSelectionModelChange={(itm) => console.log(itm)}
        // onRowClick={(row) => console.log(row)}
        onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) =>
          handleCellEditRev(params.row, params.field, event.target.value)
        }
        // onRowDoubleClick={(row, event) => handleCellEdit(row, event)}
        disableRowSelectionOnClick
      />
    </Box>
  )
}
