import logo from "./logo.svg"
import "./App.css"
import Form from "./components/newform"
import Update from "./components/update"
import Report from "./components/report"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import SignIn from "./components/signin"
import { auth } from "./firebase/config"
import ResponsiveAppBar from "./components/dashboard"
import Approval from "./components/approval"
import UserProfile from "./components/userprofile"
import MyIncentives from "./components/myincentives"
import sanityClient from "@sanity/client"
import Paid from "./components/paid"
import SalesManTarget from "./components/salesmantargets"
import Rejected from "./components/rejected"

const client = sanityClient({
  projectId: "3g2qblp5",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: false,
  token: process.env.REACT_APP_SANITY,
})
let admin = await client.fetch(`*[_type == "admin"]`)

function App() {
  console.log(auth)

  const isAdminEmail = (email) => {
    return admin.some((adminItem) => adminItem.email === email)
  }

  const isAdmin = isAdminEmail(auth.currentUser?.email)

  return (
    <div style={{ textAlign: "center" }}>
      <Router>
        {/* Show AppBar only when user is logged in */}
        {auth.currentUser && <ResponsiveAppBar />}

        <img src="ashoka.png" className="logo" alt="logo" />

        <Routes>
          {/* Home Route */}
          <Route path="/" element={auth.currentUser ? <Form /> : <SignIn />} />

          {/* Update Route */}
          <Route
            path="/update"
            element={
              auth.currentUser ? (
                isAdmin ? (
                  <Update />
                ) : (
                  <p>Please request access to Admin</p>
                )
              ) : (
                <SignIn />
              )
            }
          />

          {/* Report Route */}
          <Route
            path="/payment"
            element={
              auth.currentUser ? (
                isAdmin ? (
                  <Report />
                ) : (
                  <p>Please request access to Admin</p>
                )
              ) : (
                <SignIn />
              )
            }
          />
          <Route
            path="/approval"
            element={
              auth.currentUser ? (
                isAdmin ? (
                  <Approval />
                ) : (
                  <p>Please request access to Admin</p>
                )
              ) : (
                <SignIn />
              )
            }
          />
          <Route
            path="/paid"
            element={
              auth.currentUser ? (
                isAdmin ? (
                  <Paid />
                ) : (
                  <p>Please request access to Admin</p>
                )
              ) : (
                <SignIn />
              )
            }
          />
          <Route
            path="/rejected"
            element={
              auth.currentUser ? (
                isAdmin ? (
                  <Rejected />
                ) : (
                  <p>Please request access to Admin</p>
                )
              ) : (
                <SignIn />
              )
            }
          />
          <Route
            path="/salesmantargets"
            element={
              auth.currentUser ? (
                isAdmin ? (
                  <SalesManTarget />
                ) : (
                  <p>Please request access to Admin</p>
                )
              ) : (
                <SignIn />
              )
            }
          />
          <Route
            path="/profile"
            element={auth.currentUser ? <UserProfile /> : <SignIn />}
          />
          <Route
            path="/myincentives"
            element={auth.currentUser ? <MyIncentives /> : <SignIn />}
          />
        </Routes>
      </Router>
    </div>
  )
}

export default App
