import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import AdbIcon from "@mui/icons-material/Adb"
import LogoutIcon from "@mui/icons-material/Logout"
import { useNavigate } from "react-router-dom"
import { auth } from "../firebase/config"
import { signOut } from "firebase/auth"
import MyIncentives from "./myincentives"
import sanityClient from "@sanity/client"

const settings = ["Logout"]

const client = sanityClient({
  projectId: "3g2qblp5",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: false,
  token: process.env.REACT_APP_SANITY,
})
let admin = await client.fetch(`*[_type == "admin"]`)

function ResponsiveAppBar() {
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const [pages, setPages] = React.useState([
    { name: "INCENTIVE FORM", link: "/" },
    { name: "MY INCENTIVES", link: "/myincentives" },
    // { name: "UPDATES", link: "/update" },
    // { name: "APPROVALS", link: "/approval" },
    { name: "PROFILE", link: "/profile" },
  ])

  React.useEffect(() => {
    const isAdminEmail = (email) => {
      return admin.some((adminItem) => adminItem.email === email)
    }

    const isAdmin = isAdminEmail(auth.currentUser?.email)
    console.log(isAdmin)
    if (isAdmin) {
      setPages([
        { name: "INCENTIVE FORM", link: "/" },
        { name: "UPDATES", link: "/update" },
        { name: "APPROVALS", link: "/approval" },
        { name: "PAYMENTS", link: "/payment" },
        { name: "PAID", link: "/paid" },
        { name: "REJECTED", link: "/rejected" },
        { name: "PROFILE", link: "/profile" },
        { name: "PERFORMANCE", link: "/salesmantargets" },
      ])
    }
  }, [auth.currentUser])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const logout = async () => {
    await signOut(auth)
    window.location.reload()
  }
  return (
    <AppBar position="static" sx={{ backgroundColor: "black" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography> */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "block", lg: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => navigate(page.link)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            LOGO
          </Typography> */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "none", lg: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => navigate(page.link)}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                  borderRadius: 1,
                  px: 1,
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            <Typography sx={{ mr: 2 }}>{auth.currentUser?.email}</Typography>
            <Tooltip title="Logout">
              <IconButton onClick={logout} sx={{ p: 0 }}>
                <LogoutIcon style={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
