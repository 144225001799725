import React, { useState, useEffect } from "react"
import DataReport from "./datareport"
import sanityClient from "@sanity/client"
import dayjs from "dayjs"
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material"

import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"

const client = sanityClient({
  projectId: "3g2qblp5",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: false,
  token: process.env.REACT_APP_SANITY,
})

const SalesManTarget = () => {
  const [incentive, setIncentive] = useState([])
  const [monthlyIncentive, setMonthlyIncentive] = useState([])
  const [fine, setFine] = useState([
    { fine: "", sales_target: "", remark: "", task_product: [] },
  ])
  const [count, setCount] = useState(0)
  const [value, setValue] = React.useState(
    dayjs(
      new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    )
  )
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount)
  }

  const calculatePercentage = (current, target) => {
    return Math.min(Math.round((current / target) * 100), 100)
  }

  useEffect(() => {
    console.log(value.toISOString().split("T")[0])
    let currentDate = new Date(value)
    const pastDate = new Date(value)
    pastDate.setDate(currentDate.getDate() - 60)

    console.log("past", pastDate.toISOString())
    const data = async () => {
      const query = `*[_type == "salesman"]`
      const fine_response = await client.fetch(query)
      console.log("fine", fine_response)
      if (fine_response.length !== 0) {
        setFine(fine_response)
      }

      // setIncentive(productlist_response)
    }
    data()
  }, [])

  console.log("fine", fine)

  useEffect(() => {
    let current_date = new Date()
    const current_month = current_date.getMonth() + 1 // Months are 0-based

    console.log(value.toISOString().split("T")[0])
    function toISOWithIST(date) {
      const offset = 5.5 * 60 * 60 * 1000 // IST is UTC+5:30 in milliseconds
      const istDate = new Date(date.getTime())
      return istDate.toISOString() //.replace("Z", "+05:30")
    }
    const data = async () => {
      const startOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        1
      )

      const endOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        1
      )

      console.log(toISOWithIST(startOfMonth), toISOWithIST(endOfMonth))
      const query = `*[_type == "sales" && approved == true && status == "paid" && _createdAt >= "${toISOWithIST(
        startOfMonth
      )}" && _createdAt <= "${toISOWithIST(endOfMonth)}"] | order(_createdAt)`
      const productlist_response = await client.fetch(query)
      setIncentive(productlist_response)
      const result = Object.values(
        productlist_response.reduce((acc, item) => {
          const {
            user,
            sales_man,
            sales_man_email,
            achieved_slab_amount,
            quantity,
            sale_amount,
          } = item
          if (!acc[user]) {
            acc[user] = {
              user,
              sales_man,
              sales_man_email,
              total_incentive: 0,
              total_sales: 0,
            }
          }
          if (!acc[user].sales_man && sales_man) {
            acc[user].sales_man = sales_man
          }
          acc[user].total_incentive +=
            parseFloat(achieved_slab_amount) * parseInt(quantity, 10)
          acc[user].total_sales +=
            parseFloat(sale_amount) * parseInt(quantity, 10)

          return acc
        }, {})
      )
      setMonthlyIncentive(result)
    }
    data()
  }, [count])

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  console.log(incentive, monthlyIncentive)

  let array = []

  incentive.map((item) => {
    let obj = {
      serial_no: item.serial_no,
      date: item._createdAt,
      bill_series: item.bill_series,
      bill_no: item.bill_no,
      name: item.sales_man,
      brand: item.brand,
      product: item.product,
      model: item.model_no,
      sale_amount: item.sale_amount,
      mop: item.item_mop,
      sp: item.item_sp,
      lp: item.item_lp,
      nlc: item.nlc,
      achieved_slab: item.achieved_slab,
      incentive: item.achieved_slab_amount,
      quantity: item.quantity,
      total_Incentive: item.achieved_slab_amount * item.quantity,
      margin: (item.sale_amount - item.nlc) * item.quantity,
      exchange: item.exchange_product,
      status: item.status,
      bill_signedby: item.bill_signedby,
      remarks: item.remarks,
    }
    array.push(obj)
  })

  const exportToCSV = (fileName) => {
    const ws = XLSX.utils.json_to_sheet(array)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div className="monthlyincentiveadmin">
        {monthlyIncentive.map((item, index) => (
          <Card
            sx={{
              maxWidth: 300,
              minWidth: 300,
              // margin: "20px auto",
              backgroundColor: "#f5f5f5",
              boxShadow: 3,
              minHeight: 400,
            }}
          >
            <CardContent>
              <Typography variant="h4" color="primary">
                {item.sales_man}
              </Typography>
              <Typography variant="h7" color="primary">
                {item.sales_man_email}
              </Typography>
              {fine.filter(
                (fineitem) => fineitem.email === item.sales_man_email
              )[0]?.sales_target && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{ fontWeight: "bold" }}
                  >
                    Sales Target
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={calculatePercentage(
                          item.total_sales,
                          parseFloat(
                            fine.filter(
                              (fineitem) =>
                                fineitem.email === item.sales_man_email
                            )[0]?.sales_target
                          )
                        )}
                        sx={{ height: 10, borderRadius: 5 }}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">
                        {`${calculatePercentage(
                          item.total_sales,
                          parseFloat(
                            fine.filter(
                              (fineitem) =>
                                fineitem.email === item.sales_man_email
                            )[0]?.sales_target
                          )
                        )}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" align="left">
                    {`${formatCurrency(item.total_sales)} / ${formatCurrency(
                      parseFloat(
                        fine.filter(
                          (fineitem) => fineitem.email === item.sales_man_email
                        )[0]?.sales_target
                      )
                    )}`}
                  </Typography>
                </Box>
              )}

              {fine.filter(
                (fineitem) => fineitem.email === item.sales_man_email
              )[0]?.task_product && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{ fontWeight: "bold" }}
                  >
                    Task Product
                  </Typography>
                  <Typography variant="subtitle2" align="left">
                    {" "}
                    {
                      fine.filter(
                        (fineitem) => fineitem.email === item.sales_man_email
                      )[0]?.task_product[0]?.model
                    }
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={calculatePercentage(
                          incentive
                            .filter(
                              (incitem) =>
                                incitem.model_no ===
                                  fine.filter(
                                    (fineitem) =>
                                      fineitem.email === item.sales_man_email
                                  )[0]?.task_product[0]?.model &&
                                incitem.approved === true &&
                                incitem.sales_man_email === item.sales_man_email
                            )
                            .reduce(
                              (sum, item) =>
                                sum + (parseFloat(item.quantity) || 0),
                              0
                            ),
                          parseFloat(
                            fine.filter(
                              (fineitem) =>
                                fineitem.email === item.sales_man_email
                            )[0]?.task_product[0]?.quantity
                          )
                        )}
                        sx={{ height: 10, borderRadius: 5 }}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">
                        {`${calculatePercentage(
                          incentive
                            .filter(
                              (incitem) =>
                                incitem.model_no ===
                                  fine.filter(
                                    (fineitem) =>
                                      fineitem.email === item.sales_man_email
                                  )[0]?.task_product[0]?.model &&
                                incitem.approved === true &&
                                incitem.sales_man_email === item.sales_man_email
                            )
                            .reduce(
                              (sum, item) =>
                                sum + (parseFloat(item.quantity) || 0),
                              0
                            ),
                          parseFloat(
                            fine.filter(
                              (fineitem) =>
                                fineitem.email === item.sales_man_email
                            )[0]?.task_product[0]?.quantity
                          )
                        )}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" align="left">
                    {`${incentive
                      .filter(
                        (incitem) =>
                          incitem.model_no ===
                            fine.filter(
                              (fineitem) =>
                                fineitem.email === item.sales_man_email
                            )[0]?.task_product[0]?.model &&
                          incitem.approved === true &&
                          incitem.sales_man_email === item.sales_man_email
                      )
                      .reduce(
                        (sum, item) => sum + (parseFloat(item.quantity) || 0),
                        0
                      )} / ${parseFloat(
                      fine.filter(
                        (fineitem) => fineitem.email === item.sales_man_email
                      )[0]?.task_product[0]?.quantity
                    )}`}
                  </Typography>
                </Box>
              )}
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Monthly Incentive
              </Typography>
              <Typography variant="h4" color="secondary">
                ₹{item?.total_incentive?.toFixed(2)}
              </Typography>
              {fine.filter(
                (fineitem) => fineitem.email === item.sales_man_email
              )[0]?.fine && (
                <>
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Fine
                  </Typography>
                  <Typography
                    variant="h4"
                    color="secondary"
                    style={{ color: "red" }}
                  >
                    ₹
                    {parseFloat(
                      fine.filter(
                        (fineitem) => fineitem.email === item.sales_man_email
                      )[0]?.fine
                    )}
                  </Typography>
                  <Typography
                    variant="p"
                    color="secondary"
                    style={{ fontWeight: "bold", color: "red" }}
                  >
                    {
                      fine.filter(
                        (fineitem) => fineitem.email === item.sales_man_email
                      )[0]?.remark
                    }
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default SalesManTarget
