import * as React from "react"
import Box from "@mui/material/Box"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "@mui/material"

const columns = [
  { field: "_id", headerName: "ID", width: 300 },
  {
    field: "product",
    headerName: "Product",
    width: 150,
    editable: true,
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 150,
    editable: true,
  },

  {
    field: "name",
    headerName: "Model",
    width: 450,
    editable: true,
  },
  {
    field: "nlc",
    headerName: "NLC",
    width: 100,
    editable: true,
  },
  {
    field: "mop",
    headerName: "MOP",
    width: 100,
    editable: true,
  },
  {
    field: "sp",
    headerName: "SP",

    width: 100,
    editable: true,
  },
  {
    field: "lp",
    headerName: "LP",

    width: 100,
    editable: true,
  },
  {
    field: "mop_incentive",
    headerName: "MOP Incentive",
    width: 150,
    editable: true,
  },
  {
    field: "sp_incentive",
    headerName: "SP Incentive",
    width: 150,
    editable: true,
  },
  {
    field: "lp_incentive",
    headerName: "LP Incentive",
    width: 150,
    editable: true,
  },
  {
    field: "formula",
    headerName: "Formula",
    width: 150,
    editable: true,
  },
]

export default function Data({
  productlist,
  selectedrows,
  setSelectedRows,
  handleDeleteSelectedRows,
}) {
  const rows = productlist
  return (
    <Box sx={{ height: 700, width: "100%" }}>
      <Button
        variant="contained"
        style={{ marginBottom: "2%" }}
        disabled={selectedrows.length === 0}
        onClick={handleDeleteSelectedRows}
      >
        DELETE SELECTED ({selectedrows.length})
      </Button>
      <DataGrid
        getRowId={(item) => item._id}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          // const selectedIDs = new Set(ids);
          // const selectedRowData = rows.filter((row) =>
          //   selectedIDs.has(row.id.toString())
          // )
          setSelectedRows(newRowSelectionModel)
          console.log("newRowSelectionModel", newRowSelectionModel)
        }}
      />
    </Box>
  )
}
