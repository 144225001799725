import React, { useEffect, useState } from "react"
import { getAuth, updateProfile, updatePassword } from "firebase/auth"
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Grid,
  Divider,
  Alert,
} from "@mui/material"

const UserProfile = () => {
  const [user, setUser] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [updatedName, setUpdatedName] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    const auth = getAuth()
    const currentUser = auth.currentUser
    if (currentUser) {
      setUser({
        name: currentUser.displayName,
        email: currentUser.email,
        uid: currentUser.uid,
      })
      setUpdatedName(currentUser.displayName || "")
    } else {
      console.log("No user is signed in.")
    }
  }, [])

  const handleUpdateProfile = async () => {
    const auth = getAuth()
    const currentUser = auth.currentUser
    try {
      if (updatedName && updatedName !== user.name) {
        await updateProfile(currentUser, { displayName: updatedName })
      }
      if (newPassword) {
        await updatePassword(currentUser, newPassword)
      }
      setMessage("Profile updated successfully.")
      setEditMode(false)
      setUser({
        ...user,
        name: updatedName,
      })
      setNewPassword("")
    } catch (error) {
      setMessage(`Error: ${error.message}`)
    }
  }

  return (
    <Box sx={{ padding: 3, maxWidth: 600, margin: "0 auto" }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          User Profile
        </Typography>
        <Divider sx={{ my: 2 }} />
        {user ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <strong>Name:</strong> {user.name || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <strong>Email:</strong> {user.email}
              </Typography>
            </Grid>
            {editMode ? (
              <Grid item xs={12}>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    label="Update Name"
                    variant="outlined"
                    fullWidth
                    value={updatedName}
                    onChange={(e) => setUpdatedName(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="New Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    autoComplete="new-password"
                    sx={{ mb: 2 }}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdateProfile}
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setEditMode(true)}
                >
                  Edit Profile
                </Button>
              </Grid>
            )}
          </Grid>
        ) : (
          <Typography>No user is logged in.</Typography>
        )}
        {message && (
          <Alert
            severity={message.includes("Error") ? "error" : "success"}
            sx={{ mt: 2 }}
          >
            {message}
          </Alert>
        )}
      </Paper>
    </Box>
  )
}

export default UserProfile
